import { StorageSerializers, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, watch } from 'vue';
import { config } from '~/config';
import { CalendarsRecord, CalendarsResponse } from '~/types/pb';
import { useApi } from './useApi';

export const selectedCalendarId = useStorage<string | null>(
  `${config.localStoragePrefix}selected-calendar-id`,
  null,
  undefined,
  {
    serializer: StorageSerializers.object,
  },
);

export const useCalendarStore = defineStore('calendar', () => {
  const api = useApi();
  const { data: calendar, error } = api.col('calendars').getOne(selectedCalendarId);

  watch(error, (error) => {
    if (error) {
      selectedCalendarId.value = null;
    }
  });

  const { data: _businessHours } = api.col('calendar_business_hours').getList({
    filter: computed(() => {
      if (!calendar.value) {
        return undefined;
      }

      return `calendar="${calendar.value.id}"`;
    }),
  });

  const businessHours = computed(() =>
    _businessHours.value.map((businessHour) => ({
      daysOfWeek: [businessHour.day_of_week],
      startTime: businessHour.start_time,
      endTime: businessHour.end_time,
    })),
  );

  const { data: owner } = api.col('calendar_owners').getList({
    filter: computed(() => {
      if (!calendar.value) {
        return undefined;
      }

      return `calendar="${calendar.value.id}"`;
    }),
  });

  return {
    selectedCalendarId,
    calendar: calendar as unknown as CalendarsResponse,
    businessHours,
    owner,
  };
});
