import { createPinia } from 'pinia';
import { createApp } from 'vue';
import 'virtual:windi.css';
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '~/assets/custom-theme.css';

import App from '~/App.vue';
import router from '~/router';
import '~/lib/dayjs';

import 'core-js/full';

const app = createApp(App);

app.use(createPinia());
app.use(PrimeVue);
app.use(router());

app.mount('#app');
