import PocketBase from 'pocketbase';
import { config } from '~/config';
import { Record as PBRecord } from 'pocketbase';
import { CollectionRecords, CollectionResponses, Collections } from '~/types/pb';
import { useRecordStore } from './useRecordStore';

const client = new PocketBase(config.apiUrl || undefined);
// client.autoCancellation(false);

type Store<T extends keyof CollectionRecords> = ReturnType<typeof useRecordStore<CollectionRecords[T] & PBRecord>>;
const recordsStores: { [Key in keyof CollectionRecords]?: Store<Key> } = {};

export function useApi() {
  if (Object.values(recordsStores).length === 0) {
    for (const collection of Object.values(Collections)) {
      recordsStores[collection] = useRecordStore(client, collection) as Store<any>;
    }
  }

  function col<T extends keyof CollectionResponses, S = CollectionResponses[T]>(collection: T) {
    const store = recordsStores[collection] as Store<T>;
    const c = client.collection(collection);
    return {
      create: c.create.bind(c)<S>,
      update: c.update.bind(c)<S>,
      delete: c.delete.bind(c),
      getOne: store.getOne,
      getList: store.getList,
      getFirstFromList: store.getFirstFromList,
    };
  }


  return Object.assign(client, { col });
}
