import { defineStore } from 'pinia';
import Pocketbase, { Record as PBRecord } from 'pocketbase';
import { computed, Ref, ref, watch } from 'vue';
import { evaluate } from '~/lib/filter/evaluator';
import { get } from 'lodash';

const PER_PAGE = 50;

export function useRecordStore<S extends PBRecord>(client: Pocketbase, collectionName: string) {
  return defineStore(collectionName, () => {
    const rawRecords = ref<{ [key: string]: S }>({});
    const collection = client.collection(collectionName);

    function setRecord(id: string, item: S) {
      rawRecords.value[id] = item;
    }

    function deleteRecord(id: string) {
      delete rawRecords.value[id];
    }

    function getRecord(id: string): S | undefined {
      return rawRecords.value[id];
    }

    const records = computed(() => Object.values(rawRecords.value) as S[]);

    function getOne(id: Ref<string | undefined | null>): {
      data: Ref<S | undefined>;
      loading: Ref<boolean>;
      error: Ref<Error | undefined>;
    } {
      const loading = ref(false);
      const error = ref<Error>();

      async function getItem(_id: string | undefined | null) {
        if (_id) {
          loading.value = true;
          try {
            const record = await collection.getOne(_id);
            setRecord(_id, record as S);
          } catch (e) {
            error.value = e as Error;
          }
          loading.value = false;
        }
      }

      watch(id, getItem);
      getItem(id.value);

      const data = computed(() => (id.value ? getRecord(id.value) : undefined));

      return { data, loading, error };
    }

    function getList({
      filter,
      sort,
      page,
      perPage,
      pause,
      all,
    }:
      | {
          filter?: Ref<string | undefined>;
          sort?: Ref<string | undefined>;
          page?: Ref<number | undefined>;
          perPage?: Ref<number | undefined>;
          pause?: Ref<boolean>;
          all?: Ref<boolean>;
        }
      | undefined = {}): {
      data: Ref<S[]>;
      totalItems: Ref<number | undefined>;
      loading: Ref<boolean>;
      error: Ref<Error | undefined>;
    } {
      const loading = ref(false);
      const totalItems = ref<number>();
      const error = ref<Error>();

      async function getItemsBatch(page: number, perPage: number) {
        const options: Record<string, string | boolean> = {};
        if (filter?.value) {
          options.filter = filter.value;
        }
        if (sort?.value) {
          options.sort = sort.value;
        }

        // options.$autoCancel = false;

        try {
          const list = await collection.getList<S>(page, perPage, options);
          return {
            items: list.items,
            totalItems: list.totalItems,
          };
        } catch (e) {
          error.value = e as Error;
          return {
            error: e as Error,
          };
        }
      }

      async function getItems() {
        if (pause?.value || (filter && filter.value === undefined)) {
          return;
        }

        loading.value = true;
        error.value = undefined;

        const _perPage = perPage?.value || PER_PAGE;

        let batch = 0;
        let fetchedItems = 0;
        while (true) {
          const res = await getItemsBatch((page?.value || 0) + batch, _perPage);
          res.items?.forEach((item) => {
            setRecord(item.id, item);
          });

          if (res.error) {
            console.error('error', res.error);
            break;
          }

          fetchedItems += res.items.length;
          totalItems.value = res.totalItems;

          if (all?.value !== true) {
            break;
          }

          if (fetchedItems >= totalItems.value) {
            break;
          }

          batch++;
        }

        loading.value = false;
      }

      if (filter) {
        watch(filter, getItems);
      }
      if (sort) {
        watch(sort, getItems);
      }
      if (page) {
        watch(page, getItems);
      }
      if (pause) {
        watch(pause, getItems);
      }

      getItems();

      const filteredRecords = computed(() => {
        if (!filter) {
          return records.value;
        }

        if (filter.value === undefined) {
          return [];
        }

        const allItems = records.value.filter((record) =>
          evaluate(filter.value as string, (key: string) => {
            if (key === 'null') {
              return null;
            }
            if (key === 'false') {
              return false;
            }
            if (key === 'true') {
              return true;
            }
            return get(record, key);
          }),
        );

        const _page = page?.value || 0;
        const _perPage = perPage?.value || PER_PAGE;

        if (all?.value === true) {
          return allItems;
        }

        return allItems.slice(_page * _perPage, (_page + 1) * _perPage);
      });

      return { data: filteredRecords, totalItems, loading, error };
    }

    function getFirstFromList({
      filter,
      sort,
      pause,
    }:
      | {
          filter?: Ref<string | undefined>;
          sort?: Ref<string | undefined>;
          pause?: Ref<boolean>;
        }
      | undefined = {}): {
      data: Ref<S | undefined>;
      totalItems: Ref<number | undefined>;
      loading: Ref<boolean>;
      error: Ref<Error | undefined>;
    } {
      const { data: items, totalItems, loading, error } = getList({ filter, sort, perPage: ref(1), pause });

      return {
        data: computed(() => (items.value.length > 0 ? items.value[0] : undefined)),
        totalItems,
        loading,
        error,
      };
    }

    collection.subscribe('*', ({ action, record }) => {
      if (action === 'create' || action === 'update') {
        setRecord(record.id, record as S);
      }
      if (action === 'delete') {
        deleteRecord(record.id);
      }
    });

    return { records, rawRecords, setRecord, deleteRecord, getRecord, getOne, getList, getFirstFromList };
  })();
}
